// eslint-disable-next-line no-redeclare,no-unused-vars
function handleMenuActive(param = {}) {
  var ident = param.ident;
  if (param.objIdent) ident = param.objIdent;

  var menu = $('#menu');
  menu.find('[data-ident].active:not(.active-fix)').removeClass('active');
  if (ident) {
    menu
      .find('[data-ident=' + ident + ']')
      .addClass('active')
      .parents('div:first')
      .prev()
      .addClass('active');

    if ($('#sidebar').hasClass('sidebar-mini'))
      menu.find('[data-ident=' + ident + ']').addClass('show');
  }
}
